import paronApi from '@/utils/paron-api'
export default {
    getAllPartners() {
        return paronApi.get('partner/all')
    },

    getPartnerById(id) {
        return paronApi.get(`partner/${id}`)
    },

    createPartner(data) {
        return paronApi.post('partner/new', data)
    },

    editPartnerById(id, data) {
        return paronApi.post(`partner/${id}/edit`, data)
    },

    deletePartnerById(id) {
        return paronApi.delete(`partner/${id}`)
    },
    getPartnersByFilter(page, filter, sortOption, order) {
        var path = page ? `partner/filtered?page=${page}&per_page=10&` : 'partner/filtered?'
        if (sortOption) {
            path += `sortOption=${sortOption}&order=${order}`
        }
        return paronApi.post(path, filter)
    },
    toggleApproved(id) {
        return paronApi.post(`partner/${id}/toggleApprove`)
    },
    getAllCategories() {
        return paronApi.get('category/all')
    }

}
